import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles(() => ({
    contenedor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50
    },
    boleta: {
        width: '50%',
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    titulo: {
        textAlign: 'left'
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 10
    },
    datePicker: {

    },
    motivo: {
        width: '90%',
    },
    boton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginBottom: 10
    }
}));

export default function Boleta(props) {
    const classes = useStyles();
    const [fechaInicial, setFechaInicial] = useState(Date.now());
    const [fechaFinal, setFechaFinal] = useState(Date.now());
    const [motivo, setMotivo] = useState('');
    const [open, setOpen] = useState(false);
    const [enviado, setEnviado] = useState(false);

    async function enviarPedido() {
        if (motivo === '') {
            setOpen(true);
            return;
        }
        let params = {
            nombre: props.user.email,
            fechaInicial: fechaInicial,
            fechaFinal: fechaFinal,
            motivo: motivo
        }
        const response = await fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/registrarVacaciones', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });
        console.log(response);

        fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/enviarBoleta', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });

        setEnviado(true);
    }

    function aceptarEnvio() {
        setEnviado(false);
        window.open('/', '_self');
    }

    return (
        <Container className={classes.contenedor}>
            <Paper elevation={2} className={classes.boleta}>
                <Typography variant='h4' className={classes.titulo}>Boleta de vacaciones</Typography>
                <Container className={classes.input}>
                    <Typography variant='h5'>Desde</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            value={fechaInicial}
                            onChange={fecha => setFechaInicial(Date.parse(fecha))}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Container>
                <Container className={classes.input}>
                    <Typography variant='h5'>Hasta</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            value={fechaFinal}
                            onChange={fecha => setFechaFinal(Date.parse(fecha))}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Container>
                <Container className={classes.input}>
                    <TextField onChange={event => setMotivo(event.target.value)} className={classes.motivo} label="Motivo*" multiline rows='4' variant="outlined" />
                </Container>
                <Container className={classes.boton}>
                    <Button color='primary' variant="contained" style={{ marginRight: 60 }} onClick={enviarPedido}>
                        Enviar
                    </Button>
                </Container>
            </Paper >

            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Por favor complete el motivo de sus vacaciones.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={enviado}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Solicitud enviada
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={aceptarEnvio} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}
