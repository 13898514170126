const firebaseConfig = {
  apiKey: "AIzaSyBSSJPle4LlppW0cSrpu2xVFKNcbtp0G-o",
  authDomain: "icsa-vacaciones.firebaseapp.com",
  databaseURL: "https://icsa-vacaciones.firebaseio.com",
  projectId: "icsa-vacaciones",
  storageBucket: "icsa-vacaciones.appspot.com",
  messagingSenderId: "863154455267",
  appId: "1:863154455267:web:b948a9c42572a98fbefbd6",
  measurementId: "G-5KL40DBNZV"
};

  export default firebaseConfig;