import React, { useState, useEffect } from 'react';
import './App.css';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './util/firebaseConfig';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Header from "./Componentes/Header";
import SignIn from './Componentes/SignIn';
import Tablero from './Componentes/Tablero';
import Boleta from './Componentes/Boleta';
import RegistroBoletas from './Componentes/RegistroBoletas';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fa6320' },
    secondary: { main: "#0F1A59" },
  },
  status: {
    danger: "#282c34",
  },
});

firebase.initializeApp(firebaseConfig);

export default function App () {
  const [user, setUser] = useState([]);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('User is signed in.');
        setUser(user);
      } else {
        console.log('No user is signed in.');
        setUser(null);
      }
    });
  });

  if (!user) {
    return (
      <ThemeProvider theme={theme}>
        <Container className="App">
          <SignIn />
        </Container>
      </ThemeProvider>
    );
  }

  if (user) {
    return (
      <ThemeProvider theme={theme}>
        <Container className="App" maxWidth={false}>
          <Router>
            <Header/>
            <Switch>
              <Route path="/registroBoletas" render={() => <RegistroBoletas user={user} />} />
              <Route path="/boletaVacaciones" render={() => <Boleta user={user} />} />
              <Route path="/" render={() => <Tablero user={user} />} />
            </Switch>
          </Router>
        </Container>
      </ThemeProvider>
    );
  }
}