import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { CardHeader } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Box } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(() => ({
    tablero: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50
    },
    fila: {
        heigth: '30%',
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    carta: {
        width: 300,
        minHeight: 200,
        margin: 10
    },
    header: {
        backgroundColor: '#010326'
    },
    tituloCarta: {
        textAlign: 'left',
        color: '#fa6320',
    },
    contenido: {
        fontSize: 18
    },
    acciones: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 48
    }

}));

export default function Tablero(props) {
    const classes = useStyles();

    return (
        <Container className={classes.tablero}>
            <Container className={classes.fila}>
                <Card className={classes.carta}>
                    <CardHeader title='Días acumulados' classes={{title: classes.tituloCarta, root: classes.header}}/>
                    <CardContent>
                        <Typography variant='h1'>6</Typography>
                    </CardContent>
                </Card>
                <Card className={classes.carta}>
                    <CardHeader title='Boleta de vacaciones' classes={{title: classes.tituloCarta, root: classes.header}}/>
                    <CardContent>
                        <Typography variant='body1' className={classes.contenido}>Llenar la boleta de solicitud de vacaciones.</Typography>
                    </CardContent>
                    <CardActions className={classes.acciones}>
                        <IconButton color="primary" href="/boletaVacaciones">
                            <ArrowForwardIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            </Container>
            <Container className={classes.fila}>
                <Box className={classes.carta}/>
                <Card className={classes.carta}>
                    <CardHeader title='Boletas anteriores' classes={{title: classes.tituloCarta, root: classes.header}}/>
                    <CardContent>
                        <Typography variant='body1' className={classes.contenido}>Ver el registro de solicitudes anteriores.</Typography>
                    </CardContent>
                    <CardActions className={classes.acciones}>
                        <IconButton color="primary" href="/registroBoletas">
                            <ArrowForwardIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            </Container>
        </Container>
    );
}