import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
    registroBoletas: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50
    },
    titulo: {
        margin: 20,
        width: '55%',
        textAlign: 'left'
    },
    boleta: {
        width: '60%'
    }
}));

export default function RegistroBoletas(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [solicitudes, setSolicitudes] = useState([]);
    const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio","julio", "agosto", "setiembre", "octubre", "noviembre", "diciembre"];
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getSolicitudes() {
            const response = await fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/getSolicitudesVacaciones', {
                method: 'POST',
                body: JSON.stringify({ nombre: props.user.email }),
                headers: {
                    "Content-type": "application/json"
                }
            });
            const jsonResponse = await response.json()
            if (!ignore) setSolicitudes(jsonResponse);
        }
        getSolicitudes();
        return () => { ignore = true; }
    }, [props.user.email]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container className={classes.registroBoletas}>
            <Typography variant="h4" className={classes.titulo}>Solicitudes registradas</Typography>
            {solicitudes.map((solicitud, index) => {
                var fechaInicial = new Date(parseInt(solicitud.FechaInicio));
                var fechaFinal = new Date(parseInt(solicitud.FechaFinal));
                return (
                <ExpansionPanel className={classes.boleta}
                    key={`panel${index}`} 
                    expanded={expanded === `panel${index}`} 
                    onChange={handleChange(`panel${index}`)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                            Desde el {fechaInicial.getDate()} de {meses[fechaInicial.getMonth()]} del {`${fechaInicial.getFullYear()} `} 
                            hasta el {fechaFinal.getDate()} de {meses[fechaFinal.getMonth()]} del {fechaFinal.getFullYear()}.
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography variant="body1">{solicitud.Motivo}</Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                );
            })}
        </Container>
    );
}